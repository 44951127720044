'use client'
import React, { useState } from 'react'
import { Loader,
  // PasswordInput,
  Box, TextInput } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { Notifications, notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons-react'
export default function Home() {
  const clipboard = useClipboard({ timeout: 5000 })
  const [url, setUrl] = useState<string>('')
  const [shoturl, setShortUrl] = useState<string>('')
  const [popout, setPopout] = useState<boolean>(false)
  const [urlFetch, setUrlFetch] = useState<boolean>(false)

  async function generateUrl() {
    setUrlFetch(true)
    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      cache: 'no-cache',
      body: JSON.stringify({
        shortUrl: url,
      }),
    }
    // @ts-ignore
    return await fetch('api/thor', requestOptions)
      .then((response: any) => response.json())
      .then((result: any) => {
        if (result && result.status === 200) {
          setShortUrl(process.env.DOMAIN_URL + '/' + result.shortUrl)
          setUrlFetch(false)
          setPopout(true)
        } else {
          notifications.show({
            id: 'shortening-url-error',
            title: 'Error in shortening url',
            message: result.msg,
            withCloseButton: true,
            autoClose: 3000,
            classNames: {
              title: 'dark:text-white text-dark',
              body: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black mr-10',
              root: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black w-fit',
              closeButton: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black dark:hover:bg-dpDarkColor1',
            },
          })
          setShortUrl('')
          setUrlFetch(false)
          setPopout(false)
        }
      })
      .catch((error: any) => {
        notifications.show({
          id: 'shortening-url-error',
          title: 'Error in shortening url',
          message: error,
          withCloseButton: true,
          autoClose: 3000,
          classNames: {
            title: 'dark:text-white text-dark',
            body: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black',
            root: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black',
            closeButton: 'dark:bg-dpDarkColor1 bg-dpDarkColor1 dark:text-white text-black dark:hover:bg-dpDarkColor1',
          },
        })
        setShortUrl('')
        setUrlFetch(false)
        setPopout(false)
        console.log(error)
      })
  }

  return (
    <main className="m-0 flex min-h-screen flex-col items-center justify-center p-24">
      <div className="z-10 w-full max-w-5xl items-center justify-between font-mono text-sm lg:flex">
        {/*<div className="fixed bottom-0 left-0 flex h-48 w-full items-end justify-center bg-gradient-to-t from-white via-white dark:from-black dark:via-black lg:static lg:h-auto lg:w-auto lg:bg-none">*/}
        {/*  <a*/}
        {/*    className="pointer-events-none flex place-items-center gap-2 p-8 lg:pointer-events-auto lg:p-0"*/}
        {/*    href="https://www.linkedin.com/in/din%C3%A7er-pe%C3%A7e-bb10b622b/"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    By <span className="relative text-xl dark:text-white dark:drop-shadow-[0_0_0.3rem_#ffffff70]">dincerpece</span>*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
      <div className="mb-[25%] relative flex w-full flex-col place-items-center before:absolute before:h-[300px] before:w-[480px] before:-translate-x-1/2 before:rounded-full before:bg-gradient-radial before:from-white before:to-transparent before:blur-2xl before:content-[''] after:absolute after:-z-20 after:h-[180px] after:w-[240px] after:translate-x-1/3 after:bg-gradient-conic after:from-sky-200 after:via-blue-200 after:blur-2xl after:content-[''] before:dark:bg-gradient-to-br before:dark:from-transparent before:dark:to-blue-700 before:dark:opacity-10 after:dark:from-sky-900 after:dark:via-[#0141ff] after:dark:opacity-40 before:lg:h-[360px]">
        {popout && (
          <div className={'flex w-full max-w-[340px] flex-col items-center justify-between dark:text-white sm:flex-row'}>
            <span className={'text-sm text-ellipsis overflow-hidden mr-1'}>{shoturl}</span>
            <button
              className={
                'mt-5 flex cursor-pointer items-center justify-center rounded border bg-black pb-1 pl-2 pr-2 pt-1 text-sm text-white dark:border-dpDarkBorderColor1 dark:hover:border-dpDarkBorderHoverColor1 sm:mt-0 w-fit'
              }
              onClick={() => clipboard.copy(shoturl)}
            >
              {clipboard.copied ? (
                <div className={'flex flex-row text-dpMainColor1'}>
                  <span className={'mr-1'}>Copied</span>
                  <IconCheck size={18} />
                </div>
              ) : (
                <span>Copy</span>
              )}
            </button>
          </div>
        )}
        <Box maw={340} mx="auto" className={'z-10 mt-5 w-full'}>
          <TextInput
            classNames={{
              input: 'dark:bg-dpDarkColor1 dark:border-dpDarkBorderColor1 dark:focus:border-dpDarkBorderHoverColor1 focus:border-dpDarkBorderHoverColor1 dark:text-white text-black',
            }}
            type={'text'}
            placeholder="URL"
            autoComplete="off"
            value={url}
            onChange={(event) => setUrl(event.currentTarget.value)}
          />

          <button
            disabled={urlFetch}
            className={
              'mt-5 flex w-full cursor-pointer items-center justify-center rounded border bg-black pb-1 pl-3 pr-3 pt-1 text-white dark:border-dpDarkBorderColor1' +
              ` dark:hover:border-dpDarkBorderHoverColor1 min-h-[34px] ${urlFetch && 'pointer-events-none'}`
            }
            onClick={generateUrl}
          >
            {urlFetch ? <Loader size="sm" className={'stroke-dpMainColor1 dark:stroke-dpMainColor1'} /> : <span>short</span>}
          </button>
        </Box>
      </div>

      {/*<div className="mb-0 mt-10 grid gap-4 text-center lg:mb-4 lg:mt-0 lg:grid-cols-4 lg:gap-12 lg:text-left">*/}
      {/*  <a*/}
      {/*    href="https://www.linkedin.com/in/din%C3%A7er-pe%C3%A7e-bb10b622b/"*/}
      {/*    className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    <h2 className={`text-1xl mb-3 font-semibold`}>*/}
      {/*      Linkedin <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">-&gt;</span>*/}
      {/*    </h2>*/}
      {/*    <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>Get linkedin profile.</p>*/}
      {/*  </a>*/}

      {/*  <a*/}
      {/*    href="https://github.com/dincerpece"*/}
      {/*    className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    <h2 className={`text-1xl mb-3 font-semibold`}>*/}
      {/*      Github <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">-&gt;</span>*/}
      {/*    </h2>*/}
      {/*    <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>Get github profile.</p>*/}
      {/*  </a>*/}

      {/*  <a*/}
      {/*    href="https://www.npmjs.com/~dpece"*/}
      {/*    className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    <h2 className={`text-1xl mb-3 font-semibold`}>*/}
      {/*      NPM <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">-&gt;</span>*/}
      {/*    </h2>*/}
      {/*    <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>Get npm profile.</p>*/}
      {/*  </a>*/}

      {/*  <a*/}
      {/*    href="https://blog.dincerpece.com"*/}
      {/*    className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    <h2 className={`text-1xl mb-3 font-semibold`}>*/}
      {/*      My site <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">-&gt;</span>*/}
      {/*    </h2>*/}
      {/*    <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>Get my site.</p>*/}
      {/*  </a>*/}
      {/*</div>*/}
      <Notifications position="top-right" className={'w-fit'} />
    </main>
  )
}
